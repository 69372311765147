import {Business, Person} from "@mui/icons-material";
import {Autocomplete, Checkbox, Chip, debounce, Grid, TextField, Typography} from "@mui/material";
import {isArray} from "lodash";
import {createElement, Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {RecipientSummary} from "../../domain/Recipients";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {SimpleButton} from "../SimpleButton";
import {SimpleTextInput} from "../SimpleTextInput";
import {useTenantId} from "../TenantContextProvider";
import {RecipientSearchDetailsDialog} from "./RecipientSearchDetailDialog";

interface RecipientSearchV2Props {
	label: string;
	values: RecipientSummary[];
	onChange: (value: RecipientSummary[]) => void;
	recipients?: boolean;
	departments?: boolean;
	multiple?: boolean;
	candidates?: RecipientSummary[];
	disabled?: boolean;
	readonly?: boolean;
	required?: boolean;
	addManualRecipientsAllowed?: boolean;
}

export function RecipientSearchV2(props: RecipientSearchV2Props) {
	const {
		label,
		values,
		onChange,
		recipients,
		departments,
		multiple,
		candidates,
		disabled,
		readonly,
		required,
		addManualRecipientsAllowed,
	} = props;
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const [needle, setNeedle] = useState<string>("");
	const [filteredOptions, setFilteredOptions] = useState<RecipientSummary[]>(candidates || []);
	const [loading, setLoading] = useState<boolean>(false);
	const [detailSearch, setDetailSearch] = useState<boolean>(false);
	const [manualRecipientName, setManualRecipientName] = useState<string>("");
	const [manualRecipientEmail, setManualRecipientEmail] = useState<string>("");
	const [manualRecipientPhone, setManualRecipientPhone] = useState<string>("");

	useEffect(() => {
		setFilteredOptions(candidates || []);
		if (!multiple && candidates && candidates.length == 1) {
			onChange([candidates[0]]);
		}
	}, [candidates, multiple]);

	const handleChange = useCallback(
		(event: SyntheticEvent, newValue: RecipientSummary | RecipientSummary[] | null) => {
			if (newValue == null) {
				onChange([]);
				return;
			}
			if (!isArray(newValue)) {
				onChange([newValue]);
				return;
			}
			onChange(newValue);
		},
		[],
	);

	const handleNeedleChange = useCallback(async (event: SyntheticEvent, value: string) => {
		setNeedle(value);
		if (!recipients && !departments) {
			throw new Error(`Cannot search for recipients nor for departments: not an allowed state`);
		}
		if (!value) {
			setFilteredOptions(candidates || []);
			return;
		}
		setLoading(true);
		try {
			const response = await RecipientsProvider.lookup(tenantId, value.split(" "));
			const filtered = response.recipients
			.sort((a,b)=>a.recipientName<b.recipientName?-1:1)
			.filter((recipient) =>
				departments && recipient.departmentId || recipients && !recipient.departmentId
			);
			setFilteredOptions(filtered);
		} catch (e) {
			setFilteredOptions([]);
		}
		setLoading(false);
	}, [candidates, recipients, departments]);

	const handleNeedleChangeDebounced = debounce(handleNeedleChange, 300);

	const performLabelLookup = useCallback((recipient: RecipientSummary) => {
		if (isArray(recipient)) {
			throw new Error("RecipientSearchV2: performLabelLookup: recipient is array");
		}
		if (recipient) {
			return recipient.recipientName;
		}
		return "";
	}, []);

	const performDepartmentLookup = useCallback((recipient: RecipientSummary) => {
		if (recipient && !recipient.departmentId && recipient.department) {
			return recipient.department;
		}
	}, []);

	const value = useMemo<RecipientSummary | null>(() => {
		if (values && values.length > 0) {
			return values[0];
		}
		return null;
	}, [values]);

	const handleDialogSelect = useCallback((selected: RecipientSummary) => {
		if (multiple) {
			onChange([...values, selected]);
		} else {
			onChange([selected]);
		}
		setDetailSearch(false);
	}, [values, multiple]);

	const handleManuallyAddedRecipient = (recipient: RecipientSummary) => {
		setManualRecipientName("");
		setManualRecipientPhone("");
		setManualRecipientEmail("");
		handleDialogSelect(recipient);
	};

	return (
		<Fragment>
			<Autocomplete
				multiple={multiple}
				options={filteredOptions}
				getOptionLabel={performLabelLookup}
				noOptionsText={needle ? t("recipients.noneFound", {needle}) : t("recipients.pleaseType")}
				loading={loading}
				onChange={handleChange}
				value={multiple ? values : value}
				fullWidth
				renderOption={(props, option, {selected}) => (
					<li key={option.recipientId || option.email} {...props}>
						{multiple
							&& <Checkbox sx={{mr: 1}} checked={selected} />}
						<Fragment>
							<Grid container spacing={1}>
								<Grid item>
									<span>{performLabelLookup(option)}</span>
								</Grid>
								{performDepartmentLookup(option)
									&& (
										<Grid item>
											<Chip label={performDepartmentLookup(option)} size="small" />
										</Grid>
									)}
								<Grid item>
									{<Chip label={option.departmentId ? <Business /> : <Person />} size="small" />}
								</Grid>
							</Grid>
						</Fragment>
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						required={required}
						autoFocus
						variant="outlined"
						label={label}
						helperText={t("recipients.hint")}
					/>
				)}
				onInputChange={handleNeedleChangeDebounced}
				readOnly={readonly}
				disabled={disabled}
				onDoubleClick={() => setDetailSearch(true)}
			/>
			<RecipientSearchDetailsDialog
				open={detailSearch}
				onClose={() => setDetailSearch(false)}
				onSelected={handleDialogSelect}
				candidates={candidates}
			/>
			{addManualRecipientsAllowed
				&& (
					<Fragment>
						<Grid container spacing={gs} style={{marginTop: 10}}>
							<Grid item style={{flexGrow: 1}}>
								<Typography variant="h5">{t("recipients.add")}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2">{t("recipients.addDescription")}</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={gs} style={{padding: 10}}>
							<Grid item sm={3} xs={12}>
								<SimpleTextInput
									size="small"
									label={t("recipients.name")}
									value={manualRecipientName}
									onChange={setManualRecipientName}
									required
								/>
							</Grid>
							<Grid item sm={3} xs={12}>
								<SimpleTextInput
									size="small"
									label={t("recipients.email")}
									value={manualRecipientEmail}
									onChange={setManualRecipientEmail}
									required
								/>
							</Grid>
							<Grid item sm={3} xs={12}>
								<SimpleTextInput
									size="small"
									label={t("recipients.phone")}
									value={manualRecipientPhone}
									onChange={setManualRecipientPhone}
								/>
							</Grid>
							<Grid item sm={3} xs={12}>
								<SimpleButton
									label={t("add")}
									loading={manualRecipientName.length == 0 || manualRecipientEmail.length == 0}
									onClick={() =>
										handleManuallyAddedRecipient(
											{
												recipientName: manualRecipientName,
												email: manualRecipientEmail,
												phone: manualRecipientPhone,
											} as RecipientSummary,
										)}
								/>
							</Grid>
						</Grid>
					</Fragment>
				)}
		</Fragment>
	);
}
